

















































import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class extends Vue {
  @Prop({}) open!: boolean
  @Prop({}) loading!: boolean
}
